:root {
  --paddingrl: calc(5px + 5vw);

  --white: #ffffff;

  --bkg: #252525;
  --blue: #2e35db;
  --gray: #646464;
  --bluebutton: #4149f6;
}
