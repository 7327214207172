.choosemodal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.displaynone {
  display: none;
}
.closezone {
  transition: 0.2s;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  right: 0;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: hsla(0, 0%, 94.5%, 0.274);
}
.choosezone {
  background-color: rgb(41, 41, 41);
  z-index: 3;
  overflow: scroll;
  height: auto;
  width: 500px;
  max-height: 500px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px 50px 9px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
  border-radius: 6px;
  color: white;
}

.tdinput {
  position: relative;

  border: 1px solid rgb(122, 122, 122);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 45px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: auto;
  p {
    margin: 0;
  }
  .input {
    width: 25px;
    position: relative;
    left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid #afafaf81;
    width: 20px;
    height: 20px;
    margin: 0;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: 0;
    position: relative;
    cursor: pointer;
    filter: 1 !important ;
  }
  [type="checkbox"]:not(:checked) {
    background-color: transparent;
  }
  [type="checkbox"]:checked {
    background-image: url(../../img/check.svg);
    background-size: 95%;
    filter: invert(1);
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid #4444449a;
    padding: 0;
    background-color: rgba(201, 147, 0, 0.26);
  }
}
.opacity {
  opacity: 0.12;
}

.texte {
  font-size: calc(9px + 0.2vw);
  font-weight: 300;
  text-align: center;
  width: 100%;
  font-family: "Poppins" !important;
  padding-top: 10px;
}

.validate {
  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 25px;
  .button {
    background-color: var(--bluebutton);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
}
